.portfoliocontainer {
  display: flex;
  justify-content: center;
  /*   margin: 0 78px; /* This sets equal margins on the left and right */
  max-width: 1440px; /* Optional: Limits the maximum width of the container */
  width: 82%;
  margin-top: 56px;
  flex-direction: column;
  align-items: center;
}

.portfoliocontent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectcardsgrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Three columns per row */
  /* grid-gap: 20px;  Adjust the gap between cards as needed */
  justify-items: center;
}

@media (max-width: 950px){
  .projectcardsgrid {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .portfoliocontent {
    width: 100%;
  }
}
