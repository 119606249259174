.panelStyle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.imageStyle1 {
  /* width: 60%; */
  /* margin-left: -40%;
  bottom: -2%; */
  /* left: -2%; */
  position: relative;
  border-radius: 30px;
  height:100%
  /* box-shadow: 1px 2px 6px rgb(0, 0, 0); */
}

.imageStyle2 {
  width: 60%;
  /* margin-right: -6%;
  top: -18%;
  right: -0%; */
  position: relative;
  border-radius: 30px;
  /* box-shadow: 0 2px 6px rgb(0, 0, 0); */
}

.bottomImageContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.taurusIcon {
  height: 100px;
  opacity: 80%;
  position: relative;
  top: -8%;
  right: 18%;
}
