/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 12px; /* Width of the scroll bar */
  }
  
  ::-webkit-scrollbar-track {
    background: #333; /* Background color of the track */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #000; /* Scroll bar color */
    border-radius: 10px; /* Rounded corners */
    border: 2px solid #333; /* Border around the thumb */
  }
  
  /* For Firefox */
  * {
    /* scrollbar-width: thin; */
    scrollbar-color: #111111 #333;
  }