.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .fade-bottom::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Adjust the height as needed */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
    pointer-events: none; /* Ensure the pseudo-element doesn't interfere with user interactions */
  }

.icon {
    height: 48px;
    width: 48px;
  }

.icon2 {
    height: 30px;
    width: 30px;
}
  
  @media (max-width: 1024px) {
    .icon {
      height: 48px;
      width: 48px;
    }
    .icon2 {
        height: 30px;
        width: 30px;
      }
  }
  
  @media (max-width: 768px) {
    .icon {
      height: 30px;
      width: 30px;
    }
    .icon2 {
        height: 30px;
        width: 30px;
      }
  }
  
  @media (max-width: 640px) {
    .icon {
      height: 30px;
      width: 30px;
    }
    .icon2 {
        height: 30px;
        width: 30px;
      }
  }
  
  @media (max-width: 200px) {
    .icon {
      height: 30px;
      width: 30px;
    }
    .icon2 {
        height: 30px;
        width: 30px;
      }
  }