@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-lg;
  }
  body {
    @apply bg-neutral-950 overflow-x-hidden
  }
}

:root {
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}

/* body {
  padding-right: 0px !important;
  
} */

/* body {
  padding-right: 0px !important;} */