.contactcontainer {
    display: flex;
    justify-content: flex-start;
    /*   margin: 0 78px; /* This sets equal margins on the left and right */
    max-width: 1440px; /* Optional: Limits the maximum width of the container */
    width: 82%;
    margin-top: 56px;
    flex-direction: column;
    align-items: center;
    height: 680px;
  }