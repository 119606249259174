.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #0A0A0A;
  z-index: 9999;
  height: 72px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.floatingHeader {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #0A0A0A;
  z-index: 9999;
  height: 72px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.3s ease;
  padding: 0px 32px;
}

.header {
  background-color: rgba(17, 9, 66, 0);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  margin: 0px 8px;
/*   font-family: "poppins"; */
  max-width: 1280px;
  width: 100%;
  max-height: 73px;
  flex-direction: row;
}

.leftsection {
  max-height: 60px; /* Adjust the logo height as needed */
  /* padding-right: 20px;  Add padding around the logo */
  padding: 8px 0px 0px 0px;
}

.rightsection {
  color: white;
  text-decoration: none;
  /* margin-right: 36px; */ /* Add spacing between navigation links */
  cursor: pointer;
  display: flex;
    flex-direction: row;
    gap: 36px;
}

.logo {
  height: 42px;
}

.active {
  border-bottom: 2px solid white;
/*   font-family: "poppins"; */
  font-size: 20px;
  font-weight: 500;
}

.link {
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: #fff;
  cursor: pointer;
  text-decoration: none; /* Remove default text underline */
/*   font-family: "poppins"; */
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%; /* Start the underline from the middle of the text */
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: all 0.2s ease-in-out; /* Transition all properties for smooth effect */
  transform-origin: center; /* Set the transform origin to the center */
  transform: scaleX(0); /* Initially scale the underline to zero width */
}

.link:hover::before {
  left: 0; /* Move the pseudo-element to the left edge */
  width: 100%; /* Expand the underline to 100% of the text width */
  transform-origin: center; /* Keep the transform origin at the center */
  transform: scaleX(1); /* Expand the underline to full width */
}

.link:hover {
  text-decoration: none; /* Remove underline on hover */
}

.fadein {
  /* opacity: 1; */
  transition: opacity 0.5s ease; /* You can adjust the duration and timing function as needed */
}

.mobileMenuButton {
  display: none; /* Hide the button by default */
}

/* Media query for smaller screen sizes */
@media (max-width: 850px) {
  .rightsection {
    display: none; /* Hide the navigation links by default */
  }

  .mobileMenuButton {
    display: block; /* Show the button for mobile */
    color: white;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .showMobileMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 71px; /* Adjust the top position as needed */
    right: 0px; /* Adjust the right position as needed */
    background-color: #09090b;
    padding: 10px;
    z-index: 9999;
    gap: 70px;
    width: 100%;
    height: 92vh;
    opacity: 96%;
    padding-top: 22%;
  }

  .iasc {
    top: 98px; 

  }

  .showMobileMenu a {
    margin-bottom: 10px;
    align-self: center;
  }
}
