.aboutcontainer {
  display: flex;
  justify-content: space-between;
  /*   margin: 0 78px; /* This sets equal margins on the left and right */
  max-width: 1280px; /* Optional: Limits the maximum width of the container */
  width: 1280px;
  /* margin-top: 56px; */
  margin: 26px 0px 56px 0px;
}

.leftpanel {
  width: 50%;
  /* padding: 20px; */
}

.rightpanel {
  width: 50%;
  /* padding: 20px; */
  /* Style your photo carousel here */
}

.paragraphtext {
  font-size: 18px;
}

.gridcontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 20px; /* Adjust the gap as needed */
}

.griditem {
  /* border: 1px solid #ccc; /* Add borders or other styles as needed 
    padding: 20px; */
}

.gridheading {
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
}

.bannercarousel {
  height: 216px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #0A0A0A; */
  color:#ffffff;
  flex-direction: column;
}

.bannerslider {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; /* Optional: Center vertically if needed */
  text-align: center; /* Optional: Center the text horizontally within each div */
  width: 82%;
  max-width: 1440px;
}

.bannersliderimg {
  display: block;
  margin: 0 auto;
  max-width: 100%; /* Optional: Ensure the image doesn't exceed its container width */
  height: 82px;
  width: 82px;
  padding-bottom: 8px;
}

.bannersliderimglbl {
  color: white;
}

.circularContainer {
  width: 166px; /* Set the width of the circular container */
  height: 166px; /* Set the height of the circular container */
  overflow: hidden; /* Hide overflow content */
  border-radius: 50%; /* Make the container circular */
  position: relative; /* Establish positioning context */
  border-radius: 60% 40% 40% 60% / 60% 40% 60% 40%;
  animation: flow 4s infinite ease-in-out;
}

.circularImage {
  width: 100%; /* Ensure the image covers the entire container */
  height: auto; /* Maintain aspect ratio */
  position: absolute; /* Position the image absolutely */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the image within the container */
  object-fit: cover; /* Cover the container without stretching the image */
}

.slider {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center ;
  width: 82%;
  max-width: 1440px;
}

@media (max-width: 950px){
  .rightpanel {
    display: none;
  }

  .leftpanel {
    width: 100%;
  }

  .gridcontainer {
    display: flex;
    flex-direction: column;
  }

  .slider {
    width: auto;
  }
}

@keyframes flow {
  0% {
    border-radius: 60% 40% 40% 60% / 60% 40% 60% 40%;
  }
  35% {
    border-radius: 80% 70% 60% 60% / 80% 70% 60% 60%;
  }
  70% {
    border-radius: 70% 90% 60% 70% / 80% 90% 50% 90%;
  }
  100% {
    border-radius: 60% 40% 40% 60% / 60% 40% 60% 40%;
  }
  
}

