.sectionWithBackground {
  /* background-image: url("../images/pot-background7.png"); */
  /* background-size: cover;
  background-repeat: round;
  background-position: center center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100%; */
  padding:16px 32px 0px 32px;
}

.stickyHeader {
  position: fixed;
  top: 0;
  width: 100%;
  /* background-color: #0A0A0A; */
  z-index: 9999;
  height: 72px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.3s ease;
}

.headshot {
  max-height: 763px;
  height: 763px;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.48);
  border-radius: 48px; */
  position: absolute;
}

.imageContainer {
  /* flex: 1; */
  width: 40%;
  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
  /* padding: 16px 0px; */
  right: -38px;
  top:0px;
  padding-top: 55px;
  position: relative;
  opacity: 0; /* Initially hide the image */
  transition: opacity 2s ease-in; /* Apply the transition */
}

.imageContainer.loaded {
  opacity: 1; /* Make the image visible when the loaded class is applied */
}

/* .maincontainer {
  font-family: poppins;
  background-color: #f8f2ed;
} */

.introContainer {
  /* flex: 1; */
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  /* padding: 16px 0px ; */ 
  z-index: 999;
  padding-top: 155px;
  font-family: "poppins";
  color: white;
  font-size: 32px;
  opacity: 0; /* Initially hide the image */
  transition: opacity 1s ease-in; /* Apply the transition */
}

.introContainer.loaded {
  opacity: 1; /* Make the image visible when the loaded class is applied */
}

.contentwrapper {
  display: flex;
  flex-direction: row;
  height: 100%; /* Set the height to 100% to match the section */
  box-sizing: border-box;
  flex: 1;
  max-width: 1440px;
  width: 82%;
}

.textcontent {
  /* flex: 1; */ /* Allow text-content to grow and take available space */

  display: flex;
  flex-direction: column;
  font-family: "poppins";
  font-size: 44px;

  position: relative;
  padding: 20px 40px;
  background: rgba(0, 0, 0, 0.55);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.48);
  border-radius: 8px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.nametext {
  display: flex;
  gap: 12px;
  /* font-size: 70px; */
}

.imagecontent {
  flex: 1; /* Allow image-content to grow and take available space */
  display: flex;
}

.fadeinshow {
  transition: opacity 0.4s ease; /* You can adjust the duration and timing function as needed */
}

.buttonContainer {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  gap: 16px;
  padding-top: 20px;
}

.customButton {
  background-color: #C4EF4B;
  color: black;
  padding: 10px 20px; /* Adjust padding to your preference */
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-family: poppins;
  font-size: 24px;
  font-weight: 600;
}

.customButton:hover {
  background-color: #77a100; /* Change color on hover if desired */
}

.buttonText {
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: #fff;
  cursor: pointer;
  text-decoration: none; /* Remove default text underline */
  font-family: "poppins";
  font-size: 24px;
  font-weight: 600;
  display: inline-block;
  position: relative;
}

.buttonText::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%; /* Start the underline from the middle of the text */
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: all 0.2s ease-in-out; /* Transition all properties for smooth effect */
  transform-origin: center; /* Set the transform origin to the center */
  transform: scaleX(0); /* Initially scale the underline to zero width */
}

.buttonText:hover::before {
  left: 0; /* Move the pseudo-element to the left edge */
  width: 100%; /* Expand the underline to 100% of the text width */
  transform-origin: center; /* Keep the transform origin at the center */
  transform: scaleX(1); /* Expand the underline to full width */
}

.buttonText:hover {
  text-decoration: none; /* Remove underline on hover */
}

.aboutcontainer {
  display: flex;
  justify-content: space-between;
  /*   margin: 0 78px; /* This sets equal margins on the left and right */
  max-width: 1440px; /* Optional: Limits the maximum width of the container */
  width: 82%;
  margin-top: 56px;
}

.leftpanel {
  width: 50%;
  /* padding: 20px; */
}

.rightpanel {
  width: 50%;
  /* padding: 20px; */
  /* Style your photo carousel here */
}

.paragraphtext {
  font-size: 18px;
}

.gridcontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 20px; /* Adjust the gap as needed */
}

.griditem {
  /* border: 1px solid #ccc; /* Add borders or other styles as needed 
  padding: 20px; */
}

.gridheading {
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
}

.footer {
  /* background-color: #0A0A0A; */
  /* padding: 10px; */
  text-align: center;
  /* position: fixed; */
  bottom: 0;
  /* width: 100%; */
  height: 70px;
  color:#fff;
}

@media (max-width: 950px) {
  .imageContainer {
    display: none;
  }
  .contentwrapper {
    width: 100%;
  }
  .introContainer {
    width: 100%;
    align-items: center;
    padding: 0px;
  }

  .textcontent {
    margin: 36px;
  }
  
}